// export const baseURL = "http://192.168.29.136:6007/";
// export const key = "dbTmgroachhlJDGGzhRxgDYdKIvmraTs";

// export const baseURL = "http://192.168.29.136:5045";
// export const baseURL = "https://virtualdesk.codderlab.com";
// export const key = "dbTmgroachhlJDGGzhRxgDYdKIvmraTs";

// export const baseURL = "https://testvirtualdesk.codderlab.com";
export const baseURL = "https://admin.rayzi.app";
export const key = "vHlwi52J3p6Qq8gcxVHZJXEvMqrcaVwF";
    